import React from "react"

import { Section, StyledContainer } from "../../global"

const PiskotkiDescription = () => (
  <Section>
    <StyledContainer>
      <h4>Uporaba piškotkov</h4>
      <p>Spletna stran salom.si (e-poštna sporočila, spletne storitve, oglaševanje in interaktivne aplikacije za optimizacijo storitev itd.) uporablja piškotke.</p>
      <p>S piškotki vas osebno ne identificiramo. Uporabljamo jih samo za boljše delovanje spletišča.</p>
      <p>Z uporabo tega spletnega mesta se strinjate, da spletno mesto www.salom.si nastavi piškotke na vaši napravi.</p>
      <h5>Kaj so piškotki?</h5>
      <p>Piškotki so manjše besedilne datoteke, običajno iz črk in številk, katere obiskano spletno mesto shrani v napravo (računalnik, mobilnik, tablica itd.) z namenom prepoznavanja teh naprav. Shranjevanje piškotkov je pod nadzorom brskalnika – glejte spodaj: nadzor nad piškotki.</p>
      <h5>Zakaj naša spletna stran uporablja piškotke?</h5>
      <p>Glavni namen piškotka je, da (našemu) spletnemu strežniku omogoči, da uporabniku predstavi prilagojene spletne strani, ki omogočajo, da je doživetje ob obisku (naše) spletne strani bolj osebno in da se bolje odziva na posamezne uporabnikove potrebe. Interakcija med uporabnikom in spletnim mestom je s pomočjo piškotkov hitrejša in enostavnejša. Z njihovo pomočjo si spletna stran zapomni posameznikove preference in izkušnje, s tem je uporabniku ob ponovnem obisku prihranjen čas, brskanje po spletnih straneh pa je tako bolj učinkovito in prijetno.</p>
      <h5>Katere piškotke naša stran uporablja?</h5>
      <table>
        <tr>
          <th>Ime piškotka</th>
          <th>Namen uporabe</th>
          <th>Čas trajanja</th>
          <th>Podjetje</th>
        </tr>
        <tr>
          <td>google-analytics</td>
          <td>Statistika</td>
          <td>2 leti</td>
          <td>Google</td>
        </tr>
      </table>
      <h4>Nadzor nad piškotki</h4>
      <p>Če želite spremeniti način uporabe piškotkov v brskalniku, vključno z blokiranjem ali izbrisom, lahko to storite z ustrezno spremembo nastavitev brskalnika. Podrobnejša navodila so predstavljena v nadaljevanju.</p>
      <p>Google Chrome</p>
      <ul>
        <li>1. Kliknite »Orodja« na vrhu vašega brskalnika in izberite »Nastavitve«.</li>
        <li>2. Kliknite »Prikaži dodatne nastavitve«, se pomaknite do razdelka »Zasebnost« in kliknite gumb »Nastavitve vsebine«.</li>
        <li>3. Izberite »Dovoli nastavljanje lokalnih podatkov«.</li>
      </ul>
      <p>Microsoft Internet Explorer 6.0, 7.0, 8.0, 9.0</p>
      <ul>
        <li>1. Kliknite »Orodja« na vrhu vašega brskalnika in izberite »Internetne možnosti«, nato kliknite zavihek »Zasebnost«.</li>
        <li>2. Preverite, da je raven vaše zasebnosti nastavljena na srednjo ali manj, kar bo omogočilo uporabo piškotkov v vašem brskalniku.</li>
        <li>3. Nastavitev nad srednjo ravnijo bo onemogočila uporabo piškotkov.</li>
      </ul>
      <p>Mozilla Firefox</p>
      <ul>
        <li>1. Kliknite »Orodja« na vrhu vašega brskalnika in izberite »Možnosti«.</li>
        <li>2. Nato izberite ikono »Zasebnost«.</li>
        <li>3. Kliknite »Piškotki« in izberite možnost »Dovoli stranem, da ustvarijo piškote«.</li>
      </ul>
      <p>Safari</p>
      <ul>
        <li>1. Kliknite ikono zobnika na vrhu vašega brskalnika in izberite možnost »Nastavitve«.</li>
        <li>2. Kliknite »Zasebnost«, nato označite možnost »Onemogoči uporabo piškotkov tretjih oseb in oglaševalnih piškotkov«.</li>
        <li>3. Kliknite »Shrani«.</li>
      </ul>
      <h4>Preberite si več o piškotkih</h4>
      <p>Če želite vedeti več o piškotkih, obiščite katero od naslednjih strani:</p>
      <p>http://www.allaboutcookies.org</p>
      <br />
      <p>Boštjan Hari s.p.</p>
      <p>To spletno stran upravlja podjetje Boštjan Hari s.p.</p>
    </StyledContainer>
  </Section>
)

export default PiskotkiDescription
