import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/common/footer"
import PiskotkiHeader from "../components/sections/piskotki/piskotkiheader"
import PiskotkiDescription from "../components/sections/piskotki/piskotkidescription"


const Piskotki = () => (
  <Layout>
    <SEO title="Piškotki" />
    <PiskotkiHeader />
    <Navigation />
    <PiskotkiDescription />
    <Footer />
  </Layout>
)

export default Piskotki
